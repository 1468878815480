<template>
  <div>
    <div class="container-fluid">
      <div class="container">
        <div class="col-lg-8 col-12" style="padding: 0; margin: 0 auto;">
          <h1 class="page-title" style="margin-bottom: 25px;">Privacy</h1>
          <p class="gray-text" style="margin-bottom: 15px;">
            Aslice Privacy Policy
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Last Updated: June 4, 2021
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Welcome to our privacy page. We are fairly sure no one reads these
            things (except the people who write them), so before we get into the
            legal language, we want to make a direct statement about your
            privacy in plain English.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Quite simply, we respect your privacy.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            We only want the data and rights from you that we absolutely need to
            operate legally and fulfill our mission. Nothing further. We have no
            ambition to be in the business of selling user data. As stated
            elsewhere on this website, the aim of our service is to rebalance
            the DJ and producer relationship by creating a new revenue-sharing
            model.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Furthermore, we understand that for this service to work, privacy is
            paramount.
          </p>
          <p class="section-title-purple">DJs:</p>
          <p class="gray-text">
            Your DJ fees are your business. Our fee calculator does not save any
            information, and while we are asking all DJs to share 5% if they
            can, neither the total amount you pay nor the amount per producer is
            publicly visible—not even to the producers receiving money.
          </p>
          <p class="section-title-purple">Producers:</p>
          <p class="gray-text">
            Much like DJs, your earnings are kept private from all users. What
            you earn is for you alone to know, as is how and where you report
            it.
            <br />
            Introduction: <br />
            Conceived in 2020 and launched in 2021, Aslice is a
            community-focused service dedicated to creating a fairer music
            ecosystem with a software-based service that facilitates easy and
            accurate reporting and empowers DJs to directly share earnings with
            producers. Whether you use Aslice as a DJ, producer, or just a fan
            of music, we remain committed to providing a service that recognizes
            and protects your privacy.
          </p>
          <p class="section-title-purple">Who We Are:</p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Aslice, INC provides a service that empowers DJs to voluntarily
            share their earnings with producers, creating a community-based
            solution for a broken industry. We make no claim to be a music
            performing rights organization or collections society, and do not,
            and will not, ever sell or stream music. Our physical mailing
            address is 5123 W 98th Street, #2077 Minneapolis, MN, 55437 USA.
            Aslice operates with principles and key contractors working in
            Berlin, Minneapolis, New York, Detroit, Melbourne, and more. We can
            be reached via the “<span
              ><a target="_blank" class="green-link" href="/contact"
                >general contact</a
              ></span
            >” link here and in the menu located at the top right of every
            webpage on our site.
          </p>
          <p class="gray-text red-link" style="margin-bottom: 15px;">
            From here on is the “real” privacy policy that our lawyer has
            written. Be warned—it’s dense.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Aslice provides Users (as defined below) with a web-based email and
            text messaging platform with a mobile application (collectively, the
            “Services”). Learn more about our Services at Aslice.com
            (“Website”).
          </p>
          <p class="gray-text">
            By using the Aslice (“Aslice”, “we”, “us” or “our”) Services, you
            are agreeing to this Privacy Policy (“Privacy Policy”) and the
            Aslice Terms of Service (“Terms”) which we may update or amend from
            time to time as set forth herein. Capitalized words or phrases not
            defined herein have the meaning ascribed in the Terms.
          </p>
          <p class="page-title" style="line-height: 40px; margin: 35px auto;">
            Description of Users and Acceptance of Privacy Policy
          </p>
          <p class="gray-text">
            This Privacy Policy applies to users who sign up for or otherwise
            access the Services, including business entities and natural persons
            who order our Services (“Customers”); employees, consultants,
            contractors, agents and/or other representatives authorized by a
            Customer to access and use our Services (“Customer Agents”);
            business entities and natural persons who receive text messages
            through the Services form a Customer or Authorized Agent, but who do
            not have a direct relationship with us (“Third-Parties”); and those
            who utilize our Website to learn about our products and services
            (“Visitors”). Customers, Customer Agents, Third-Parties and Visitors
            are referred to herein collectively as the “Users”). By visiting,
            using, signing up, or accessing the Website and Services you
            acknowledge that you have read, understand, and agree to be legally
            bound by this Privacy Policy and our Terms of Service. If you do not
            agree to any of the provisions of this Privacy Policy or any of the
            Terms of Service, you may not access or use the Services.
          </p>
          <p class="page-title" style="line-height: 40px; margin: 35px auto;">
            Information We May Collect
          </p>
          <p class="gray-text">
            You may be asked to provide your personal information anytime you
            are in contact with Aslice, or the affiliates or subsidiaries of
            Aslice, or when using Aslice, the Services, Website or any other
            website provided by Aslice, or its subsidiaries, affiliates,
            agents/representatives/joint venturers (such as financial
            institutions, verification services, etc…). Aslice and its
            affiliates may share this personal information with each other and
            use it consistent with this Privacy Policy for purposes of providing
            the Services to you as well as providing customer service,
            conducting research, and improving the Services. For more details on
            why we collect information and how it is used please see “Use of
            Information” below. Information may also be combined with other
            information to provide and improve our products, Services, content,
            and advertising. You are not required to provide the personal
            information that we have requested, but, if you chose not to do so,
            in many cases we will not be able to provide you with our products
            or Services or respond to any inquiries you may have. By voluntarily
            providing information, including personal information, you are
            consenting to our use of it in accordance with this Privacy Policy.
            If you provide personal information to the Services, you acknowledge
            and agree that such personal information may be transferred from
            your current location to the offices and servers of Aslice and the
            authorized third parties referred to herein and our Terms of
            Service.
          </p>
          <p class="section-title-purple">
            Here are some examples of the types of information Aslice may
            collect (collectively “Personal Information”):
          </p>
          <ul
            class="gray-text"
            style="padding-left: 25px; margin-bottom: 15px;"
          >
            <li>
              Personal Data and Identifying Information for registration (e.g.
              name, email address, location);
            </li>
            <li>
              The geographical area in which you use your computer or mobile
              device, as indicated by and IP address or similar identifier;
            </li>
            <li>
              Payment processing information, including but not limited to
              account numbers, routing numbers, and other payment methods;
            </li>
            <li>The Aslice products and Services that you use;</li>
            <li>
              Access to information stored on your mobile device, including but
              not limited to access to your camera, files, photograph and any
              other digital content stored on you mobile device; and
            </li>
            <li>
              Any content that you provide or 3rd party data which may be synced
              with your account.
            </li>
          </ul>
          <p class="gray-text">
            Some information (which may be deemed personal information in some
            jurisdictions) may be automatically received and stored as
            non-identifiable personal information. Such information is collected
            passively using various technologies and cannot presently be used to
            specifically identify you. Example of this non-personal information
            includes the type of internet browser or mobile device you used, any
            Website or device from which you have come to the Services, your
            operating system location data (which is anonymous), your IP
            address, cookie ID, pages accessed and clickstream, additional
            session information and timestamps. We may store such non-personal
            information ourselves or it may be included in databases owned and
            maintained by our affiliates, agents, or service providers. The
            Services may use such non-personal information to assist us in
            providing effective Services and to collect broad demographic
            information for aggregate use.
          </p>
          <p class="section-title-purple">Cookies</p>
          <p class="gray-text" style="margin-bottom: 15px">
            When you utilize the Website or our Services we strive to make that
            experience as efficient as possible, and our web server may send
            data collection and tracking tools such as web beacons, pixel tags,
            embedded links, cookies and other similar tracking techniques to
            your computer or mobile device. Cookies are small pieces of
            information which are issued to your computer or mobile device when
            you visit a Website or access or use a mobile application, and which
            store and sometimes track information about your use of the Services
            and Website. A number of cookies we use last only for the duration
            of your web or application session and expire when you close your
            browser or exit the application. Other cookies may be used to
            remember you when you return to the Website and will last longer.
            Some cookies are set by us, and others by third parties who are
            delivering Services on our behalf. In all cases in which we use
            cookies, we will not collect Personal Information unless we obtain
            your permission.
          </p>
          <p class="gray-text">
            Most web and mobile device browsers automatically accept cookies.
            You may change or adjust your settings to prevent this if you wish.
            Please note, however, that by blocking or deleting cookies you may
            not be able to take full advantage of the Services. If you have
            questions about how to change or adjust your settings, please direct
            those inquiries to the maker of your device or service provider.
          </p>
          <p class="page-title" style="line-height: 40px; margin: 35px auto;">
            Use of Information
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            In general, Personal Data you submit to us is used either to respond
            to requests that you make or to aid us in serving you better. We and
            our authorized service providers may use your Personal Data in
            various ways, including to:
          </p>
          <ul
            class="gray-text"
            style="padding-left: 25px; margin-bottom: 15px;"
          >
            <li>
              facilitate the creation of and secure your Account on our network;
            </li>
            <li>identify you as a user in our system;</li>
            <li>
              provide improved administration of our Website, Services and
              products;
            </li>
            <li>provide the products and Services you request;</li>
            <li>
              measure and analyze audience traffic and improve the quality of
              your experience when you interact with our Website and Services;
            </li>
            <li>
              send you a welcome email to verify ownership of the e-mail address
              provided when your Account was created;
            </li>
            <li>
              send you administrative e-mail notifications, such as security, or
              support and maintenance advisories;
            </li>
            <li>
              send newsletters, surveys, offers, and other promotional materials
              and for other marketing purposes;
            </li>
            <li>
              help recognize you as a previous visitor and save and remember
              your preferences and settings and deliver to your appropriate
              interest-based content;
            </li>
            <li>
              understand whether our users read email messages and click on
              links within those messages;
            </li>
            <li>
              prevent potentially prohibited or illegal activities, comply with
              applicable law and enforce our Terms of Service; and
            </li>
            <li>
              for any other purposes disclosed to you at the time we collect
              your information or pursuant to your consent.
            </li>
          </ul>
          <p class="gray-text" style="margin-bottom: 15px;">
            These service providers will have access to your personal
            information in order to provide these Services, but when this occurs
            we implement reasonable contractual and technical protections to
            limit their use of that information to helping us provide the
            Services. We use demographic information to better understand our
            Users and improve our products and Services.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            We also use personal information to help us create, develop,
            operate, deliver, and improve our products, Services, content, and
            advertising, and for loss prevention and anti-fraud purposes.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            We may use your personal information, including date of birth, to
            verify identity, assist with identification of users, and to
            determine appropriate Services. For example, we may use date of
            birth to verify the age of account holders to ensure they are of
            legal age to use the Services we provide.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            We may also use personal information for internal purposes such as
            auditing, data analysis, and research to improve Aslice products,
            Services, and customer communications.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            We may also share or disclose your personal information with our
            affiliates and subsidiaries. We may share or disclose your personal
            information to protect our Users; to maintain the security of our
            Services; to detect, prevent, or report fraud, criminal activity, or
            violations of our terms of service/use; to protect the health and
            safety of our Users or the general public; and to protect our rights
            and property.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Some Websites may belong to ad networks that use your browsing
            history across Websites to choose which ads to display on their
            sites; the displayed ads may include advertising for Aslice.
            Websites may also offer their own opt-out methods for interest-based
            advertising.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            In addition to the sharing described elsewhere in this Privacy
            Policy, we may share personal information with companies,
            organizations or individuals outside of Aslice in a de-identified
            format when we have your consent to do so. We require opt-in consent
            for the sharing of any sensitive personal information. Sensitive
            personal information is a particular category of personal
            information relating to racial or ethnic origins, political or
            religious beliefs or sexuality.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            We will share personal information with third party companies,
            organizations, or individuals outside of the Company if we have a
            good-faith belief that access, use, preservation, or disclosure of
            the information is reasonably necessary to:
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Meet any applicable law, regulation, subpoena, legal process, or
            enforceable governmental request.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Enforce applicable Terms of Service, including investigation of
            potential violations.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Detect, prevent, or otherwise address fraud, security, or technical
            issues.
          </p>
          <p class="gray-text">
            Protect against harm to the rights, property, or safety of Aslice,
            our Users, or the public as required or permitted by law.
          </p>
          <p class="section-title-purple">Use of Other Information</p>
          <p class="gray-text" style="margin-bottom: 15px;">
            We also collect data in a form that does not, on its own, permit
            direct association with any specific individual. We may collect,
            use, transfer, and disclose non-personal information for any
            purpose. We may collect information such as occupation, language,
            zip code, area code, unique device identifier, referrer URL,
            location, and the time zone where Aslice accessed from so that we
            can better understand customer behavior and improve our products,
            Services, and advertising.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            We may collect information regarding customer activities from or
            relating to the Services, on our Website and mobile application, and
            from our other products and services. This information is aggregated
            and used to help us provide more useful information to our Users and
            to understand which parts of our Website, products, content, and
            Services are of most interest. Aggregated data is considered non
            personal information for the purposes of this Privacy Policy.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            We may collect and store details of how you use our Website and
            Services, including search queries. This information may be used to
            improve the relevancy of results provided by our Services. Except in
            limited instances to ensure quality of our Services over the
            Internet, such information will not be associated with your IP
            address.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            With your explicit consent, we may collect data about how you use
            your device, the applications and Website in order to help our
            developers improve the apps, Services and Website.
          </p>
          <p class="gray-text">
            If we do combine non-personal information with personal information
            the combined information will be treated as personal information for
            as long as it remains combined.
          </p>
          <p class="section-title-purple">Disclosure to Third Parties</p>
          <p class="gray-text" style="margin-bottom: 15px;">
            At times Aslice may make personal information available to strategic
            partners that work with Aslice to provide products, Services, or
            Content, or that help Aslice market to potential customers. Personal
            information will only be shared by Aslice to provide or improve our
            products, Services, content, and advertising; it will not be shared
            with third parties for their marketing purposes nor will it in any
            event be sold to any third party.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Aslice shares personal information with companies who provide
            services such as information processing, payment processing,
            delivering products to you, managing and enhancing user data,
            providing user service, assessing your interest in our products and
            Services (including the Services), and conducting User research or
            satisfaction surveys. These companies are obligated to protect your
            information and may be located wherever Aslice operates.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            It may be necessary − by law, legal process, litigation, and/or
            requests from public and governmental authorities within or outside
            your country of residence − for Aslice to disclose your personal
            information. We may also disclose information about you if we
            determine that for purposes of national security, law enforcement,
            or other issues of public importance, disclosure is necessary or
            appropriate. <br />
            We may also disclose information about you if we determine that
            disclosure is reasonably necessary to enforce our Terms of Service
            or protect our operations or users. Additionally, in the event of a
            reorganization, merger, or sale we may transfer any and all personal
            information we collect to the relevant third party.
          </p>
          <p class="gray-text">
            Aslice is not in the business of selling or renting your
            information. Your information will only be shared when necessary to
            provide Services, enforce the Terms of Service, as otherwise
            required by law, or in cases where you have provided your explicit
            consent.
          </p>
          <p class="section-title-purple">Retention of Personal Information</p>
          <p class="gray-text">
            Aslice will keep your personal information for the period necessary
            to fulfill the purposes outlined in this Privacy Policy and Terms of
            Service unless a longer retention period is required or permitted by
            law. If your personal information is incorrect or needs updating,
            please contact Aslice immediately at support@Aslice.com and we will
            be happy to assist.
          </p>
          <p class="section-title-purple">Protecting Your Information</p>
          <p class="gray-text">
            We take commercially reasonable technical, administrative, and
            physical safeguards to protect information from loss, misuse, and
            unauthorized access, disclosure, alteration or destruction.
            Unfortunately, however, no security system is impenetrable. We
            cannot guarantee the security of our databases or the security of
            the databases of our affiliates or third-parties which we may share
            information with, nor can guarantee that information stored with us
            will not be stolen while being transmitted over the internet.
            Specifically, email sent to us may not be secure, and you should be
            careful when determining what information you wish to communicate
            via email. If you have questions about security on our Website or
            concerning the Services please contact support@Aslice.com.
          </p>
          <p class="section-title-purple">
            Third Party Services and Payment Processing
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Aslice Websites, products, applications, and Services may contain
            links to third-party websites, products, and services. Our products
            and Services may also use or offer products or services from third
            parties. Information collected by third parties, which may include
            such things as location data or contact details, is governed by
            their privacy practices. We encourage you to learn about the privacy
            practices of those third parties.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Additionally, we may use third-party analytics services (for
            example, Google Analytics and others) to evaluate your use of the
            Website and/or Services, compile reports on activity, analyze
            performance metrics, and collect and evaluate other information
            relating to the Website and/or Services and mobile and Internet
            usage. These third parties use cookies and other technologies to
            help analyze and provide us the data. You consent to the processing
            of data about you by these analytics providers in the manner and for
            the purposes set out in this Privacy Policy.
          </p>
          <p class="gray-text">
            For online payments using credit, debit, Apple Pay, PayPal, Venmo
            and/or Automated Clearing House (ACH) payouts, we use the payment
            services of . We do not store any of your credit card information
            after you make a purchase.
          </p>
          <p class="section-title-purple">
            Use of Information by You
          </p>
          <p class="gray-text">
            As part of the Services we will provide you with information
            gathered from your website and other devices. It is solely your
            responsibility to make appropriate disclosures to visitors to your
            website and devices about the type of information that may be
            collected and its use. You are solely responsible for implementing
            and ensuring your own privacy policy which complies with all
            federal, state and local laws. Aslice is not responsible for any act
            or omission by you with regard to such information, and Aslice’s
            liability shall be limited pursuant our Terms of Service, and you
            agree to indemnify, defend, and hold Aslice harmless from and
            against any and all claims, suits or otherwise relating in any way
            to your use of information provided to you by Aslice.
          </p>
          <p class="section-title-purple">
            Choices and Exclusions
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            You may visit the Website and use the Services without providing any
            personal information. If you do not provide the required
            information, however, you will not be able to use certain Services.
          </p>
          <p class="gray-text">
            This Privacy Policy does not apply to any Personal Data or other
            information collected by us other than personal information
            collected through our Services or on the Website. It does not apply
            to any unsolicited information you voluntarily provide to us, post,
            or upload through the Services or through any other means
            (“Unsolicited Information”). This includes, but is not limited to,
            information posted to any public areas of the Website. You agree
            that Unsolicited Information becomes the intellectual property of
            Aslice to the fullest extent permitted by applicable law and agree
            to assign any and all residual rights or interest that you may have
            in such Unsolicited Information to Aslice. All Unsolicited
            Information shall be deemed to be non-confidential and we shall be
            free to reproduce, use, disclose, and distribute such Unsolicited
            Information to others without limitation or attribution to the
            fullest extent permitted by law.
          </p>
          <p class="section-title-purple">
            Children
          </p>
          <p class="gray-text">
            We do not knowingly collect personal information from children under
            the age of 18. If you are under the age of 18, please do not use the
            Website or Services and/or submit any personal information through
            the Website or Services. We encourage parents and legal guardians to
            monitor their children’s internet usage and to help enforce our
            Privacy Policy by instructing their children never to provide
            personal information on any Website or service (including the
            Services) without their permission.
          </p>
          <p class="section-title-purple">
            Links to Other Sites
          </p>
          <p class="gray-text">
            This Privacy Policy applies only to our Services and Website. Our
            Services and Website may contain links to other web sites not
            operated or controlled by us, to which our Privacy Policy does not
            apply. The links from the Service and Website do not imply that we
            have reviewed the third-party sites and we suggest contacting those
            sites directly for information regarding their respective privacy
            policies.
          </p>
          <p class="section-title-purple">
            California Privacy Notice
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Aslice’s California Privacy Notice (“California Privacy Notice”)
            applies to the processing of Personal Information of residents of
            the State of California (“California Consumers”) as required by the
            California Consumer Privacy Act of 2018 (“CCPA”). “Personal
            Information”, as used in this California privacy Notice means
            information that identifies, relates to, describes, is capable of
            being associated with or could reasonably be linked, directly or
            indirectly, with a particular California Consumer or household.
          </p>
          <p class="gray-text">
            If you are a California Consumer, this California Privacy Notice
            explains your privacy rights and is intended to inform you at or
            before the time that you provide us with your Personal Information
            how and why we process your Personal Information. If this California
            Privacy Notice and any provision in the rest of our Privacy Policy
            conflict, then this California Privacy Notice controls for the
            processing of Personal Information of California Consumers.
          </p>
          <p class="section-title-purple">
            Your California Consumer Privacy Rights
          </p>
          <p class="gray-text">
            CCPA offers California Consumers the following key privacy rights:
            Right to Access Information: You have the right to request access to
            Personal Information collected about you and information regarding
            the source of that information, the purposes for which we collect it
            and the third parties and service providers with whom we share it.
            Right to Request Deletion: You have the right to request that we
            delete certain Personal Information that we have collected from you.
            <br />
            Right to Notice of Financial Incentive: If we offer a financial
            incentive or price or service difference in exchange for your
            Personal Information, we will notify you in advance before
            collecting your Personal Information for this purpose and explain
            how you can opt in to the financial incentive or price or service
            difference. <br />
            Right to Opt-Out of Sale of Personal Information to third parties:
            Aslice does not sell your Personal Information to third parties. If
            this policy changes, we will notify you and provide you with
            information about how to opt out of sale of your Personal
            Information by us. Sharing your Personal Information with our
            service providers is not a sale of your Personal Information because
            we make sure that our service providers are contractually obligated
            to use the Personal Information only to provide services to us and
            not sell it.
          </p>
          <p class="section-title-purple">
            Our Notice at Collection of Personal Information
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            For CCPA purposes, As used in this Notice at Collection of Personal
            Information, the term Third-Party” means a person or business who
            receives a test message through our Services or a third-party, but
            do not have a direct relationship with Aslice.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Aslice generally acts as a “Business” with respect to your Personal
            Information, which means that Aslice determines how and why the
            Personal Information that Aslice collects from or about you is
            processed. When Aslice is handling Personal Information about Third
            Parties on behalf of our Users, Aslice is acting as a “Service
            Provider”, which means that Aslice can only use your Personal
            Information for specified purposes and as permitted in our contract
            with our Customer.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            This Notice at Collection of Personal Information describes our
            Personal Information collection practices when we are acting as the
            Business, including a list of the categories of Personal Information
            we collect, the purposes for which we collect Personal Information
            and the sources from which we collect Personal Information. If you
            have more questions about information collected from or about Third
            Parties, please contact the company from which you receive text
            messages because they are the “Business” for CCPA purposes.
          </p>
          <p class="gray-text">
            The following table lists categories of Personal Information that
            generally match the categories in the definition of Personal
            Information in CCPA. We indicate for each listed category the
            specific types of Personal Information in the category that we
            collect and why we collect it. We provide information about how we
            use and disclose Personal Information above in this Privacy Policy.
          </p>
        </div>
      </div>
      <div class="container" style="padding: 15px 20px;">
        <div class="col-lg-10 col-12" style="margin: 0 auto;">
          <div class="row divided-section" style="margin-top: 35px;">
            <div class="col-lg-6 col-12">
              <p class="section-title-purple">
                CCCPA Category of Personal Information
              </p>
              <p class="section-title-purple color-gray">A. Identifiers</p>
            </div>
            <div class="col-lg-6 col-12">
              <p class="section-title-purple color-gray">
                Examples of what we do/do not collect
              </p>
              <p class="gray-text" style="margin-bottom: 15px;">
                We collect your name, telephone number(s), email address(-es)
                and contact address when you create an account, an account is
                created for you or you complete a transaction. If you choose to
                create an account, we assign a username and one or more unique
                identifiers to your account.
              </p>
              <p class="gray-text">
                We collect your IP address and your Device ID automatically.
              </p>
              <p class="gray-text">
                We also collect the following Personal Information
              </p>
              <ul
                class="gray-text"
                style="padding-left: 25px; margin-bottom: 15px;"
              >
                <li>To troubleshoot problems</li>
                <li>To learn how Users interact with our Site and Services</li>
                <li>
                  To improve our products and services and develop new features
                </li>
                <li>To customize your experience</li>
                <li>To promote our products and services</li>
                <li>To communicate with you</li>
              </ul>
            </div>

            <div class="col-lg-6 col-12">
              <p class="section-title-purple">
                CCCPA Category of Personal Information
              </p>
              <p class="section-title-purple color-gray">
                B. Personal information categories listed in the California
                Customer Records statute (Cal. Civ. Code § 1798.80(e))
              </p>
            </div>
            <div class="col-lg-6 col-12">
              <p class="section-title-purple color-gray">
                Examples of what we do/do not collect
              </p>
              <p class="gray-text" style="margin-bottom: 15px;">
                We collect your name, telephone number(s), email address(-es)
                and contact address when you create an account, an account is
                created for you or you complete a transaction. If you choose to
                create an account, we assign a username and one or more unique
                identifiers to your account.
              </p>
              <p class="gray-text">
                We collect your IP address and your Device ID automatically.
              </p>
              <p class="gray-text">
                We also collect the following Personal Information
              </p>
              <ul
                class="gray-text"
                style="padding-left: 25px; margin-bottom: 15px;"
              >
                <li>To troubleshoot problems</li>
                <li>To learn how Users interact with our Site and Services</li>
                <li>
                  To improve our products and services and develop new features
                </li>
                <li>To customize your experience</li>
                <li>To promote our products and services</li>
                <li>To communicate with you</li>
              </ul>
            </div>

            <div class="col-lg-6 col-12">
              <p class="section-title-purple">
                CCCPA Category of Personal Information
              </p>
              <p class="section-title-purple color-gray">
                C. Protected classification characteristics under California or
                federal law
              </p>
            </div>
            <div class="col-lg-6 col-12">
              <p class="section-title-purple color-gray">
                Examples of what we do/do not collect
              </p>
              <p class="gray-text">
                We do not collect information about legally-protected
                classifications, including race, national origin, citizenship,
                marital status, gender, sexual orientation/identity, medical
                condition, AIDS/HIV status, genetic information, military or
                veteran status, religion, disability, political affiliations or
                activities or status as a victim of domestic violence, assault
                or stalking
              </p>
            </div>

            <div class="col-lg-6 col-12">
              <p class="section-title-purple">
                CCCPA Category of Personal Information
              </p>
              <p class="section-title-purple color-gray">
                D. Commercial information
              </p>
            </div>
            <div class="col-lg-6 col-12">
              <p class="section-title-purple color-gray">
                Examples of what we do/do not collect
              </p>
              <p class="gray-text">
                We create records of the Services purchased or considered, as
                well as your purchasing history in order to provide you with the
                Services that you have purchased and to offer other products
                that may be useful to you. You provide payment and billing
                information when you purchase our Services but we do not collect
                and store your bank account number, credit card number, debit
                card number – this information is collected by our third party
                payment processor and not shared with us.
              </p>
            </div>

            <div class="col-lg-6 col-12">
              <p class="section-title-purple">
                CCCPA Category of Personal Information
              </p>
              <p class="section-title-purple color-gray">
                E. Biometric information
              </p>
            </div>
            <div class="col-lg-6 col-12">
              <p class="section-title-purple color-gray">
                Examples of what we do/do not collect
              </p>
              <p class="gray-text">
                We do not collect information about your physiological,
                biological or behavioral characteristics.
              </p>
            </div>

            <div class="col-lg-6 col-12">
              <p class="section-title-purple">
                CCCPA Category of Personal Information
              </p>
              <p class="section-title-purple color-gray">
                F. Internet or other similar network activity
              </p>
            </div>
            <div class="col-lg-6 col-12">
              <p class="section-title-purple color-gray">
                Examples of what we do/do not collect
              </p>
              <p class="gray-text">
                We collect information about your browsing history, search
                history and information regarding your interaction with customer
                portals, websites, applications or advertisements automatically
                when you use our Site and Services.
              </p>
            </div>

            <div class="col-lg-6 col-12">
              <p class="section-title-purple">
                CCCPA Category of Personal Information
              </p>
              <p class="section-title-purple color-gray">G. Geolocation data</p>
            </div>
            <div class="col-lg-6 col-12">
              <p class="section-title-purple color-gray">
                Examples of what we do/do not collect
              </p>
              <p class="gray-text">
                We collect your IP address automatically. We may be able to
                determine your general location based on the IP address.
              </p>
            </div>

            <div class="col-lg-6 col-12">
              <p class="section-title-purple">
                CCCPA Category of Personal Information
              </p>
              <p class="section-title-purple color-gray">
                H. Audio, electronic, visual, thermal, olfactory or similar
                information.
              </p>
            </div>
            <div class="col-lg-6 col-12">
              <p class="section-title-purple color-gray">
                Examples of what we do/do not collect
              </p>
              <p class="gray-text">
                If you contact us via telephone, we may record the telephone
                call. We will notify you if a call is being recorded at the
                beginning of the telephone call. We do not collect any thermal,
                olfactory or similar information. We collect your photograph if
                you choose to give it to us through your account.
              </p>
            </div>

            <div class="col-lg-6 col-12">
              <p class="section-title-purple">
                CCCPA Category of Personal Information
              </p>
              <p class="section-title-purple color-gray">
                I. Professional or employment-related information
              </p>
            </div>
            <div class="col-lg-6 col-12">
              <p class="section-title-purple color-gray">
                Examples of what we do/do not collect
              </p>
              <p class="gray-text">
                We may use information about your position and your current
                employer (who or that is the Customer or prospective customer)
                to understand more about you in order to provide you with
                relevant information to promote our products and services, to
                deliver advertising, some of which is targeted based on your
                online activity and inferred interests, and to communicate with
                you.
              </p>
            </div>

            <div class="col-lg-6 col-12">
              <p class="section-title-purple">
                CCCPA Category of Personal Information
              </p>
              <p class="section-title-purple color-gray">
                J. Non-public education information (per the Family Educational
                Rights and Privacy Act (20 U.S.C. § 1232g, 34 C.F.R. Part 99))
              </p>
            </div>
            <div class="col-lg-6 col-12">
              <p class="section-title-purple color-gray">
                Examples of what we do/do not collect
              </p>
              <p class="gray-text">
                We do not collect information about the institutions you have
                attended or the level of education you have attained.
              </p>
            </div>

            <div class="col-lg-6 col-12">
              <p class="section-title-purple">
                CCCPA Category of Personal Information
              </p>
              <p class="section-title-purple color-gray">
                K. Inferences drawn from other categories (A. – L above) to
                create a profile about a California Consumer
              </p>
            </div>
            <div class="col-lg-6 col-12">
              <p class="section-title-purple color-gray">
                Examples of what we do/do not collect
              </p>
              <p class="gray-text">
                We may analyze your actual or likely preferences through the
                Personal Information we collect and process as described in our
                Privacy Policy.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="container"
        style="padding: 0 20px; margin-top: 15px; padding-bottom: 100px;"
      >
        <div class="col-lg-8 col-12" style="padding: 0; margin: 0 auto;">
          <p class="gray-text" style="margin-bottom: 15px;">
            Aslice does not sell your Personal Information to third parties and
            accordingly, we do not provide you with information about how to
            opt-out of sale of your Personal Information
          </p>
          <p
            class="page-title"
            style="text-transform: uppercase; line-height: 38px; margin: 25px 0;"
          >
            HOW TO SUBMIT A REQUEST TO EXERCISE YOUR CALIFORNIA PRIVACY RIGHTS
          </p>
          <p class="gray-text">
            To submit a request to exercise your privacy rights:
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Send an email to support@Aslice.com
          </p>
          <p class="gray-text">
            Please note:
          </p>
          <ul
            class="gray-text"
            style="padding-left: 25px; margin-bottom: 15px;"
          >
            <li>
              We may (and in some cases are required to) verify your identity
              before we can act on your request to exercise your privacy rights.
            </li>
            <li>
              We may not honor part or all your request – for example, certain
              information we collect may be exempt from this California Privacy
              Notice, such as public information made available by a government
              entity or information covered by another privacy law. Please also
              note that Personal Information collected in many types of business
              to business transactions ma be exempt from most of CCPA’s
              requirements. We will explain why we do not honor your request
              when we respond to you.
            </li>
          </ul>
          <p class="gray-text">
            A different California law permits California residents to request a
            notice disclosing the categories of Personal Information about you
            that we have shared with third parties for their direct marketing
            purposes during the preceding calendar year. At this time, Aslice
            does not share Personal Information with third parties for their
            direct marketing purposes.
          </p>
          <p class="page-title" style="margin: 25px 0;">
            Notice for Individuals Located In EU
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            The data controller (i.e., who determines the purpose and means of
            processing your personal data) for the personal data collected from
            or about Users pursuant to this Privacy Policy is:
          </p>
          <p class="gray-text">
            Aslice
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Attn: Legal
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            support@Aslice.com
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Please note that when we collect information about Third Parties, we
            are acting as a data processor on behalf of our Customers. If you
            are a Third Party, please contact the business or individual who
            sent you the text message.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            We need to inform users about the legal bases for our processing of
            their personal data. Our legal bases depend on the context in which
            the personal data is collected and processed. Generally, we only
            collect personal data when we need the personal data to perform a
            contract with users (such as our Terms of Service, which can be
            found
            <span
              ><a class="red-link" target="_blank" href="/terms">here</a></span
            >), when we have consent to do so or when the processing is in our
            legitimate business interests and not overridden by the privacy or
            other fundamental rights and freedoms of users (such as when we
            process personal data to prevent spam or fraud).
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            In some cases, we also may have a legal obligation to collect
            personal data from users. If we ask you to provide personal data to
            comply with a legal requirement or to perform a contract with you,
            we will make this clear at the relevant time and advise you whether
            providing your personal data is mandatory and the possible
            consequences if you do not provide your personal data.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Similarly, if we collect and use personal data in reliance on our
            legitimate interests (or those of any third party), this interest is
            typically to provide our Services and communicate with you about our
            Services and for our legitimate commercial interests, such as
            responding to queries, improving our site and Services, advising
            users about new features or maintenance or undertaking marketing
            activities. We may have other legitimate interests and if
            appropriate we will make clear our legitimate interests at the
            relevant time.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            If you have questions about or need further information concerning
            the legal basis on which we collect and use your personal data,
            please contact us using the contact details provided below.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            For personal data for which we are the data controller and for which
            applicable law grants you data protection rights, if you would like
            to review, correct, update, suppress, restrict or delete personal
            data that you have previously provided to us as or if you would like
            to receive an electronic copy of your personal data for purposes of
            transmitting it to another company (where this right to portability
            is provided to you by law), please submit your request to us as
            follows:
          </p>
          <p class="gray-text">
            Aslice
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Attn: Legal
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            support@Aslice.com
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            In your request, please make clear what personal data you would like
            to have changed, whether you would like to have your personal data
            suppressed from our database or other limitations you would like to
            put on our use of your personal data. For your protection, we only
            fulfill requests for the personal data associated with the
            particular email address that you use to send us your request, and
            we may need to verify your identity and geographic residency before
            fulfilling your request. We will try to comply with your request as
            soon as reasonably practicable and within the time periods required
            by applicable law.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Please note that we often need to retain certain personal data for
            recordkeeping purposes and/or to complete any transaction that you
            began prior to requesting a change or deletion (e.g., when you make
            a purchase or enter a promotion, you may not be able to change or
            delete the personal data provided until after the completion of such
            purchase or promotion). Our databases and other records may have
            residual personal data which will not be removed. We also may not
            allow you to review certain personal data for legal, security or
            other reasons.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            EEA residents have the right to lodge a complaint with a data
            protection authority about how we process your personal data. Please
            see
            <span
              ><a
                class="red-link"
                target="_blank"
                href="https://ec.europa.eu/info/law/law-topic/data-protection/reform/what-are-data-protection-authorities-dpas_en"
                >https://ec.europa.eu/info/law/law-topic/data-protection/reform/what-are-data-protection-authorities-dpas_en</a
              ></span
            >
            for more information.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            International Transfers Of Personal Information
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Aslice may transfer your personal information to jurisdictions other
            than the one in which you reside. These other jurisdictions may have
            data protection laws that are different from the laws of your place
            of residence (and, in some cases, not as protective).
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Specifically, our servers are located in countries which may be
            located outside of the jurisdiction where we collected the data.
            This means that, when we collect your personal information, we may
            transfer it to or process it in any of the countries in which we do
            business. Also, some of the third-party service providers with which
            we may share personal information (as described above) are located
            in and may transfer personal information to various jurisdictions
            around the world. When we transfer personal data from the EEA to a
            jurisdiction outside the EEA, we deploy appropriate technical and
            organizational safeguards consistent with the requirements of
            applicable data protection law.
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            Changes
          </p>
          <p class="gray-text" style="margin-bottom: 15px;">
            The Services and Website may change from time to time. As a result,
            we may be required or deem it necessary or desirable to change this
            Privacy Policy or our Terms of Service. We therefore reserve the
            right to update or modify this Privacy Policy or our Terms of
            Service at any time, and from time to time, and with or without
            notice to you. Any material change will be preceded by a notice
            posted on the relevant Services. If you disagree with our revisions
            to the Privacy Policy, you may deactivate your account and
            discontinue the use of our Services. Please review this Privacy
            Policy and our Terms of Service periodically, and especially before
            providing and personal information. Your continued use of the
            Services after any changes or revisions validates your agreement
            with such changes or revisions.
          </p>
        </div>
      </div>
    </div>
    <Footer> </Footer>
  </div>
</template>

<script>
import Footer from "@/components/FooterNew.vue";
export default {
  metaInfo: {
    title: "Privacy Page",
    meta: [
      {
        property: "og:description",
        content:
          "Welcome to our Privacy page. Information for DJs, Producers, Aslice Users. Description of Users and Acceptance of Privacy Policy. Legal Terms & Privacy Policy."
      },
      {
        name: "description",
        content:
          "Welcome to our Privacy page. Information for DJs, Producers, Aslice Users. Description of Users and Acceptance of Privacy Policy. Legal Terms & Privacy Policy."
      }
    ]
  },
  components: {
    Footer
  },
  mounted() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
};
</script>

<style scoped>
.container-fluid {
  padding: 3.5px 21.5px;
  padding-top: 150px;
  min-height: calc(100vh - 365px);
  background: #e5e5e5;
}
.divided-section .col-lg-6.col-12 {
  border-bottom: 1px solid #aaa3b1;
  margin-bottom: 20px;
}
.divided-section .col-lg-6.col-12 .section-title-purple {
  margin-top: 0;
}
.divided-section .col-lg-6.col-12 p.gray-text:last-child {
  padding-bottom: 15px;
}
.divided-section .col-lg-6.col-12:nth-child(even) {
  border-left: 1px solid #aaa3b1;
}
@media only screen and (max-width: 991px) {
  .divided-section .col-lg-6.col-12:nth-child(even) {
    border-left: none;
  }
}
</style>
